import * as React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { Gallery, Item } from 'react-photoswipe-gallery';

// Components
import Layout from '../../components/layout/Layout';
import RealisationCard from '../../components/card/Realisation';
import PartnerCard from '../../components/card/Partner';

// Partner
const PartnerPage = ({ pageContext, data }) => {

  const location = useLocation();
  const origin = location.origin;

  const pageImage = { 
    url: data.partner.frontmatter.image.cover ? data.partner.frontmatter.image.cover.childImageSharp.fluid.srcWebp : null,
    secureUrl: data.partner.frontmatter.image.cover ? data.partner.frontmatter.image.cover.childImageSharp.fluid.srcWebp : null,
    type: data.partner.frontmatter.image.cover ? data.partner.frontmatter.image.cover.type : null,
    width: data.partner.frontmatter.image.cover ? data.partner.frontmatter.image.cover.childImageSharp.gatsbyImageData.width : null,
    height: data.partner.frontmatter.image.cover ? data.partner.frontmatter.image.cover.childImageSharp.gatsbyImageData.height : null,
    alt: data.partner.frontmatter.image.alt ? data.partner.frontmatter.image.alt : null
  };

  const pageSchemas = [
    { 
      type: "breadcrumb",
      listItems: [
        { 
          name: "Partenaires",
          item: origin + "/partenaires/"
        },
        { 
          name: data.partner.frontmatter.title
        },
      ]
    }
  ];

  const headerBackgroundImageRandom = Math.floor(Math.random() * data.realisations.nodes.length);
  const headerBackgroundImage = data.realisations.nodes[headerBackgroundImageRandom] ? data.realisations.nodes[headerBackgroundImageRandom].frontmatter.image.cover.childImageSharp.fluid : data.partner.frontmatter.image.cover.childImageSharp.fluid;
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(51, 51, 51, 0.5), rgba(51, 51, 51, 0.1))`,
  ].reverse()

  return (
    <Layout 
      pageTitle={`${data.partner.frontmatter.meta.title} | ${data.site.siteMetadata.title} - Un cercle de qualité`}
      pageDescription={data.partner.frontmatter.meta.description} 
      pageKeywords={data.partner.frontmatter.meta.keywords} 
      pageImage={pageImage}
      pageSchemas={pageSchemas}
      headerClassName="wpo-site-header wpo-header-style-2"
    >
      <BackgroundImage
        Tag="section"
        className="wpo-page-title"
        fluid={headerBackgroundImageStack}
      >
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="wpo-breadcumb-wrap">
                {data.partner.frontmatter.name &&
                  <h2>{data.partner.frontmatter.name}</h2>
                }
                <ol className="wpo-breadcumb-wrap">
                  <li><Link to={`/`}>Accueil</Link></li>
                  <li><Link to={`/partenaires/`}>Partenaires</Link></li>
                  {data.partner.frontmatter.name &&
                    <li>{data.partner.frontmatter.name}</li>
                  }
                </ol>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>

      <div className="wpo-partner-single-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
          <div className="col-lg-4 col-12 order-first">
              <div className="blog-sidebar">
                <div className="wpo-partner-single-content-des-left">
                  <div>
                    <GatsbyImage
                      image={getImage(data.partner.frontmatter.image.card)}
                      alt={data.partner.frontmatter.image.alt}
                    />
                  </div>
                  <ul>
                    {data.partner.frontmatter.country &&
                      <li key="country">
                        <span><i className="bi bi-geo-alt-fill"> </i> </span>
                        {data.partner.frontmatter.country}
                      </li>
                    }
                    {data.partner.frontmatter.website &&
                      <li key="website">
                        <span><i className="bi bi-globe"> </i> </span>
                        <a href={data.partner.frontmatter.website} target="_blank" rel="noopener noreferrer" aria-label="website">{data.partner.frontmatter.website}</a>
                      </li>
                    }
                    {data.partner.frontmatter.email &&
                      <li key="email">
                        <span><i className="bi bi-envelope"> </i> </span>
                        <a href={"mailto:" + data.partner.frontmatter.email}>{data.partner.frontmatter.email}</a>
                      </li>
                    }
                    {data.partner.frontmatter.telephone &&
                      <li key="telephone">
                        <span><i className="bi bi-telephone"></i></span>
                        <a href={"tel:" + data.partner.frontmatter.telephone}>{data.partner.frontmatter.telephone} </a>                                
                      </li>
                    }
                  </ul>
                  {data.partner.frontmatter.social_networks &&
                    <ul className="social-links">
                      {data.partner.frontmatter.social_networks.linkedIn &&
                        <li key="linkedin"><a href={data.partner.frontmatter.social_networks.linkedIn} target="_blank" rel="noopener noreferrer" aria-label="linkedin"><i className="bi bi-linkedin"></i></a></li>
                      }
                      {data.partner.frontmatter.social_networks.facebook &&
                        <li key="facebook"><a href={data.partner.frontmatter.social_networks.facebook} target="_blank" rel="noopener noreferrer" aria-label="facebook"><i className="bi bi-facebook"></i></a></li>
                      }
                      {data.partner.frontmatter.social_networks.instagram &&
                        <li key="instagram"><a href={data.partner.frontmatter.social_networks.instagram} target="_blank" rel="noopener noreferrer" aria-label="instagram"><i className="bi bi-instagram"></i></a></li>
                      }
                      {data.partner.frontmatter.social_networks.twitter &&
                        <li key="twitter"><a href={data.partner.frontmatter.social_networks.twitter} target="_blank" rel="noopener noreferrer" aria-label="twitter"><i className="bi bi-twitter"></i></a></li>
                      }
                    </ul>
                  }
                </div>
                {data.realisations.nodes.length ?
                  <div className="widget wpo-instagram-widget">
                    <div className="widget-title">
                      <h3>Réalisations</h3>
                    </div>
                    <ul className="d-flex">
                      {data.realisations.nodes.map(realisation => (
                        <li>
                          <Link to={`/realisation/${realisation.slug}`} aria-label={realisation.frontmatter.image.alt}>
                            <GatsbyImage
                              image={getImage(realisation.frontmatter.image.card)}
                              alt={realisation.frontmatter.image.alt}
                            />
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                : null}
              </div>
            </div>
            <div className="col-lg-8 col-12 order-lg-first">
              <div className="wpo-partner-single-wrap">
                <div className="wpo-partner-single-item">
                  <MDXProvider
                    components={{
                      h2: props => <div className="wpo-partner-single-title"><h2 {...props}>{props.children}</h2></div>,
                      h3: props => <div className="wpo-partner-single-title"><h3 {...props}>{props.children}</h3></div>,
                      h4: props => <div className="wpo-partner-single-title"><h4 {...props}>{props.children}</h4></div>,
                      h5: props => <div className="wpo-partner-single-title"><h5 {...props}>{props.children}</h5></div>,
                      ul: props => <div className="list-widget"><ul {...props}>{props.children}</ul></div>,
                      a: props => <a {...props} className="text-highlight-warning" target="_blank" rel="noopener noreferrer">{props.children}</a>,
                    }}
                  >
                    <MDXRenderer
                      localImages={data.partner.frontmatter.images}
                    >
                      {data.partner.body}
                    </MDXRenderer>
                  </MDXProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {data.partner.frontmatter.galleries && data.partner.frontmatter.galleries.length ?
        <div className="wpo-partner-single-item">
          <div className="container">
            <div className="wpo-partner-single-gallery">
              <div className="wpo-partner-single-title">
                  <h3>Galerie</h3>
              </div>
              <div className="row mt-4">
                <Gallery>
                  {data.partner.frontmatter.galleries.map((gallery, index) => (
                    <>
                      {gallery && gallery.childImageSharp &&
                        <div className="col-md-4 col-sm-6 col-12">
                          <div className="wpo-p-details-img">
                            <Item
                              original={gallery.childImageSharp.fluid.srcWebp}
                              thumbnail={gallery.childImageSharp.fluid.srcWebp}
                              width={gallery.childImageSharp.original.width}
                              height={gallery.childImageSharp.original.height}
                            >
                              {({ ref, open }) => (
                                <div role="none" ref={ref} onClick={open} onKeyDown={open}>
                                  <GatsbyImage
                                    ref={ref}
                                    onClick={open}
                                    image={getImage(gallery)}
                                    alt={data.partner.frontmatter.image.alt}
                                  />
                                  <span role="none" ref={ref} onClick={open} onKeyDown={open}></span>
                                </div>
                              )}
                            </Item>
                          </div>
                        </div>
                      }
                    </>
                  ))}
                </Gallery>
              </div>
            </div>
          </div>
        </div>
      : null}
      {data.realisations && data.realisations.nodes.length ?
        <div className="wpo-project-area-s2 section-padding">
          <div className="container">
            <div className="row">
              <div className="wpo-section-title">
                <span>Toutes nos réalisations en partenariat avec {data.partner.frontmatter.name}</span>
                <h2>Nos réalisations avec {data.partner.frontmatter.name}</h2>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="wpo-project-wrap">
                <div className="sortable-gallery">
                  <div className="gallery-filters"></div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="project-grids gallery-container clearfix">
                        {data.realisations.nodes.map(realisation => (
                          <RealisationCard key={realisation.id} data={realisation} cardStyle="2" />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      : null}
      {data.otherPartners && data.otherPartners.nodes.length ?
        <div className="wpo-partner-area-s2 section-padding">
          <div className="container">
            <div className="row">
              <div className="wpo-section-title">
                <span>Ils nous font aussi confiance</span>
                <h2>Nos autres partenaires</h2>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="wpo-partner-wrap">
                <div className="sortable-gallery">
                  <div className="gallery-filters"></div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="partner-grids gallery-container clearfix">
                        {data.otherPartners.nodes.map(partner => (
                          <PartnerCard key={partner.id} data={partner} cardStyle="3" />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      : null}
    </Layout>
  )
}

PartnerPage.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    partner: PropTypes.shape({
      frontmatter: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }),
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }),
    }).isRequired
  }),
}

export const query = graphql`
  query ($id: String!, $slug: String!) {
    site: site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image {
          alt
        }
      }
    }
    partner: mdx(id: {eq: $id}, fileAbsolutePath: { regex: "/data/partners/" }) {
      frontmatter {
        meta {
          title
          description
          keywords
        }        
        name
        description
        country
        website
        social_networks {
          linkedIn
          facebook
          instagram
          twitter
        }
        image {
          cover {
            childImageSharp {
              gatsbyImageData
              fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alt
          credit {
            text
            link
          }
        }
        images {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
            fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        galleries {
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(layout: FULL_WIDTH)
            fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        home
        active
        date(formatString: "D MMMM YYYY", locale: "fr")
      }
      body
    }
    otherPartners: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/partners/" }, 
        id: {ne: $id},
        frontmatter: { active: { eq: true } }
      },
      sort: {fields: frontmatter___name, order: ASC}
      limit: 6
    ) {
      nodes {
        frontmatter {
          name
          description
          image {
            card {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            credit {
              text
              link
            }
          }
          home
          active
          date(formatString: "D MMMM YYYY", locale: "fr")
        }
        id
        slug
      }
    }
    realisations: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" }, 
        frontmatter: { pages: {partners: { in: [$slug] } }, active: { eq: true } }
      },
      sort: {fields: frontmatter___date, order: DESC}
      limit: 6
    ) {
      nodes {
        frontmatter {
          title
          description
          type
          location
          surface
          budget
          duration  
          image {
            card {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            credit {
              text
              link
            }
          }
          home
          active
          date(formatString: "D MMMM YYYY", locale: "fr")
        }
        id
        slug
      }
    }
  }
`

export default PartnerPage