import * as React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const PartnerCard = ({ data, cardStyle, cardClassName }) => {
  return (
    <>
      {cardStyle === "1" &&
        <div key={data.id} className={cardClassName}>
          <Link to={`/partenaire/${data.slug}`} aria-label={data.frontmatter.image.alt}>
            <div className="wpo-partner-item">
              <div className="wpo-partner-img">
                <GatsbyImage
                  image={getImage(data.frontmatter.image.card)}
                  alt={data.frontmatter.image.alt}
                />                
                <div className="left-border"></div>
                <div className="right-border"></div>
              </div>
              <div className="wpo-partner-text">
                {data.frontmatter.name &&
                  <h5>
                    <Link to={`/partenaire/${data.slug}`} aria-label={data.frontmatter.image.alt}>
                      {data.frontmatter.name}
                    </Link>
                  </h5>
                }
              </div>
            </div>
          </Link>
        </div>
      }
      {cardStyle === "2" &&
        <div key={data.id} className={cardClassName} data-aos="fade-up" data-aos-delay="0" data-aos-offset="-50">
          <Link to={`/partenaire/${data.slug}`} aria-label={data.frontmatter.image.alt}>
            <div className="wpo-partner-item">
              <GatsbyImage
                image={getImage(data.frontmatter.image.card)}
                alt={data.frontmatter.image.alt}
              />
              {data.frontmatter.name &&
                <h2>{data.frontmatter.name}</h2>
              } 
              {data.frontmatter.description &&        
                <p className="desc">{data.frontmatter.description}</p>
              }
              <Link to={`/partenaire/${data.slug}`} aria-label={data.frontmatter.image.alt}>Voir plus...</Link>
            </div>
          </Link>
        </div>
      }   
      {cardStyle === "3" &&
        <div key={data.id} className="grid" data-aos="fade-up" data-aos-delay="0" data-aos-offset="-50">
          <Link to={`/partenaire/${data.slug}`} aria-label={data.frontmatter.image.alt}>
            <div className="wpo-partner-item">
              <div className="wpo-partner-img">
                <GatsbyImage
                  image={getImage(data.frontmatter.image.card)}
                  alt={data.frontmatter.image.alt}
                />                
                <div className="left-border"></div>
                <div className="right-border"></div>
              </div>
              <div className="wpo-partner-text">
                {data.frontmatter.name &&
                  <h2>
                    <Link to={`/partenaire/${data.slug}`} aria-label={data.frontmatter.image.alt}>
                      {data.frontmatter.name}
                    </Link>
                  </h2>
                }
              </div>
            </div>
          </Link>
        </div>
      }
    </>
  )
}

export default PartnerCard